import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeNineComponent } from './themes/theme-nine/theme-nine.component';
import { HeaderTwoComponent } from './components/header/header-two/header-two.component';
import { FooterComponent } from './components/footer/footer.component';
import { ScrollupComponent } from './components/scrollup/scrollup.component';
import { HeroNineComponent } from './components/hero/hero-nine/hero-nine.component';
import { HeroOneComponent } from './components/hero/hero-one/hero-one.component';
import { PromoOneComponent } from './components/promo/promo-one/promo-one.component';
import { AboutComponent } from './components/about/about.component';
import { FeaturesComponent } from './components/features/features.component';
import { ScreenshotComponent } from './components/screenshot/screenshot.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { MaintenanceComponent } from './components/inner-pages/maintenance/maintenance.component';
import { TermsComponent } from './components/inner-pages/terms/terms.component';
import { FaqComponent } from './components/faq/faq.component';
import { BreadcrumbFaqComponent } from './components/breadcrumb/breadcrumb-faq/breadcrumb-faq.component';
import { FaqPageComponent } from './components/inner-pages/faq-page/faq-page.component';
import { AboutPageComponent } from './components/inner-pages/about-page/about-page.component';
import { SkillsComponent } from './components/skills/skills.component';
import { BreadcrumbAboutComponent } from './components/breadcrumb/breadcrumb-about/breadcrumb-about.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { PromoThreeComponent } from './components/promo/promo-three/promo-three.component';
import { TeamComponent } from './components/team/team.component';
import { HeaderOneComponent } from './components/header/header-one/header-one.component';
import { BreadcrumbTermsComponent } from './components/breadcrumb/breadcrumb-terms/breadcrumb-terms.component';
import { BreadcrumbContactComponent } from './components/breadcrumb/breadcrumb-contact/breadcrumb-contact.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactPageComponent } from './components/inner-pages/contact-page/contact-page.component';
import { BasicPageComponent } from './components/inner-pages/basic-page/basic-page.component';
import { BreadcrumbBasicComponent } from './components/breadcrumb/breadcrumb-basic/breadcrumb-basic.component';
import { BasicComponent } from './components/basic/basic.component';
import { StandardComponent } from './components/standard/standard.component';
import { StandardPageComponent } from './components/inner-pages/standard-page/standard-page.component';
import { WhabuttonComponent } from './components/whabutton/whabutton.component';
import { PrivacyPageComponent } from './components/inner-pages/privacy-page/privacy-page.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { BreadcrumbPrivacyComponent } from './components/breadcrumb/breadcrumb-privacy/breadcrumb-privacy.component';
import { VideoPageComponent } from './components/inner-pages/video-page/video-page.component';

@NgModule({
  declarations: [
    AppComponent,
    ThemeNineComponent,
    HeaderTwoComponent,
    FooterComponent,
    ScrollupComponent,
    HeroNineComponent,
    HeroOneComponent,
    PromoOneComponent,
    AboutComponent,
    FeaturesComponent,
    ScreenshotComponent,
    PricingComponent,
    MaintenanceComponent,
    TermsComponent,
    FaqComponent,
    BreadcrumbFaqComponent,
    FaqPageComponent,
    AboutPageComponent,
    SkillsComponent,
    BreadcrumbAboutComponent,
    AboutUsComponent,
    PromoThreeComponent,
    TeamComponent,
    HeaderOneComponent,
    BreadcrumbTermsComponent,
    BreadcrumbContactComponent,
    ContactComponent,
    ContactPageComponent,
    BasicPageComponent,
    BreadcrumbBasicComponent,
    BasicComponent,
    StandardComponent,
    StandardPageComponent,
    WhabuttonComponent,
    PrivacyPageComponent,
    PrivacyComponent,
    BreadcrumbPrivacyComponent,
    VideoPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
