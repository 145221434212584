<header class="section header-area">
  <div id="appo-header" class="main-header-area nav-white bg-primary">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light">
        <!-- Logo -->
        <a class="navbar-brand" href="#">
          <img class="logo" src="assets/img/LogoSIS-WHITE.png" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Appo Menu -->
        <div class="collapse navbar-collapse" id="appo-menu">
          <!-- Header Items -->
          <ul class="navbar-nav header-items ml-auto">
            <li class="nav-item active dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
                Inicio
              </a>
              <div class="dropdown-menu mega-menu px-3 px-md-4 py-md-4">
                <!-- Shapes Container -->
                <div class="shapes-container d-none d-lg-block">
                  <div class="shape-1"></div>
                </div>
                <div class="row">
                  <!-- Dropdown Headings -->
                  <div class="col-lg-3 dropdown-headings d-none d-lg-block">
                    <span class="fw-6">SIS S. de R.L.</span>
                    <h5 class="text-uppercase my-2">Innovación y Dinamismo</h5>
                    <p class="slag fw-5">Desarrolladores certificados &amp; Respaldado por nuestros Clientes</p>
                    <div class="dropdown-btn mt-3">
                      <a href="/#pricing" class="btn btn-bordered">Sé parte</a>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3">
                    <!-- Single Menu -->
                    <ul class="single-menu">
                      <li class="page-title d-inline-block text-uppercase mb-2">Rúbros</li>
                      <li><a class="dropdown-item" href="/">Farmacéutico</a></li>
                      <li><a class="dropdown-item" href="/">Agroindustria</a></li>
                      <li><a class="dropdown-item" href="/">Hospitalarío</a></li>
                      <li><a class="dropdown-item" href="/">Salas de Belleza</a></li>
                      <li><a class="dropdown-item" href="/">Telefonía</a></li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3">
                    <!-- Single Menu -->
                    <ul class="single-menu">
                      <li class="page-title d-inline-block text-uppercase mb-2">Servicios</li>
                      <li><a class="dropdown-item" href="/maintenance">Data Warehouse</a></li>
                      <li><a class="dropdown-item" href="/maintenance">Smart Ecommerce</a></li>
                      <li><a class="dropdown-item" href="/maintenance">App Móvil y Web</a></li>
                      <li><a class="dropdown-item" href="/maintenance">Soporte Técnico</a></li>
                      <li><a class="dropdown-item" href="/maintenance">Asesoría Técnica y Operativa</a></li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3">
                    <!-- Single Menu -->
                    <ul class="single-menu">
                      <li><img src="assets/img/LogoSIS.png" alt=""></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#features">Novedades</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#pricing">Paquetes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/about-page">Acerca de Nosotros</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contact-page">Contáctanos</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
