<footer class="section footer-area footer-bg">
  <!-- Footer Top -->
  <div class="footer-top ptb_100">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Logo -->
            <a class="navbar-brand" href="#">
              <img class="logo" src="assets/img/LogoSIS-WHITE.png" alt="">
            </a>
            <p class="text-white-50 mt-2 mb-3">Dirección: Bo. Suyapa 5ta calle 16 ave. 2da Planta Local 1 Edificio Royal Corner,</p>
            <p class="text-white-50 mt-2 mb-3">San Pedro Sula, Cortés, Honduras</p>
            <p class="text-white-50 mt-2 mb-3"><i class="fas fa-phone"></i> +504 3173-7536</p>
            <p class="text-white-50 mt-2 mb-3"><i class="fas fa-phone"></i> +504 2544-0168</p>
            <!-- Social Icons -->
            <div class="social-icons d-flex">
              <a class="facebook" href="https://www.facebook.com/SISSDERL">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-facebook-f"></i>
              </a>

              <a class="instagram" href="https://www.instagram.com/sissderl/">
                <i class="fab fa-instagram"></i>
                <i class="fab fa-instagram"></i>
              </a>
              <a class="whatsapp" href="https://wa.me/50431737536">
                <i class="fab fa-whatsapp"></i>
                <i class="fab fa-whatsapp"></i>
              </a>
              <a class="email" href="mailto:info@smasis.com?Subject=información">
                <i class="far fa-envelope"></i>
                <i class="far fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title text-white mb-2">Enlaces</h3>
            <ul>
              <li class="py-2"><a class="text-white-50" href="/">Inicio</a></li>
              <li class="py-2"><a class="text-white-50" href="/">Servicios</a></li>
              <li class="py-2"><a class="text-white-50" href="/">Blog</a></li>
              <li class="py-2"><a class="text-white-50" href="/contact-page">Contáctanos</a></li>
              <li class="py-2"><a class="text-white-50" href="/about-page">Acerca de Nosotros</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title text-white mb-2">Ayuda</h3>
            <ul>
              <li class="py-2"><a class="text-white-50" href="/faq-page">FAQ</a></li>
              <li class="py-2"><a class="text-white-50" href="/maintenance">Soporte</a></li>
              <li class="py-2"><a class="text-white-50" href="/privacy-page">Politicas de Privacidad</a></li>
              <li class="py-2"><a class="text-white-50" href="/terms">Términos &amp; Condiciones</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <!-- <h3 class="footer-title text-white mb-2">Download</h3>
            <ul>
              <li class="py-2"><a class="text-white-50" href="#">For Windows</a></li>
              <li class="py-2"><a class="text-white-50" href="#">For Linux</a></li>
              <li class="py-2"><a class="text-white-50" href="#">For IOS</a></li>
              <li class="py-2"><a class="text-white-50" href="#">For Android</a></li>
              <li class="py-2"><a class="text-white-50" href="#">For Mac</a></li>
            </ul>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Copyright Area -->
          <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
            <!-- Copyright Left -->
            <div class="copyright-left text-white-50">&copy; Copyrights 2017 - {{currentYear}} SIS S. de R.L. Todos los derechos reservados.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
