<div class="faq-page">
  <div class="all-area">
    <app-header-two></app-header-two>
    <app-whabutton></app-whabutton>
    <app-breadcrumb-faq></app-breadcrumb-faq>
    <div class="bg-gray">
      <app-faq></app-faq>
    </div>
    <app-footer></app-footer>
  </div>
</div>
