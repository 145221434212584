<section id="features" class="section features-area ptb_100">
  <div class="shapes-container">
    <div class="shape bg-shape"></div>
  </div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="features-text">
          <!-- Headings -->
          <div class="headings d-flex align-items-center mb-4">
            <span class="text-uppercase d-none d-sm-block">Features</span>
            <h2 class="text-capitalize">Creado robustamente</h2>
          </div>
          <p class="my-3">SMA es un ERP robusto diseñado para manenejar Inventarios, Contabilidad, Ventas, Compras, Planillas, Cada uno de sus Módulos cubre cada una de tus necesidades.</p>
          <!-- Counter List -->
          <div class="counter-list">
            <ul>
              <li>
                <!-- Single Counter -->
                <div class="single-counter px-4 py-3 text-center">
                  <!-- Counter Icon -->
                  <div class="counter-icon">
                    <i class="icofont-repair"></i>
                  </div>
                  <!-- Counter Text -->
                  <div class="counter-text">
<!--                    <span class="counter d-inline-block mt-3 mb-2">2350</span>   -->
                    <h5>Soporte</h5>
                  </div>
                </div>
              </li>
              <li>
                <!-- Single Counter -->
                <div class="single-counter px-4 py-3 text-center">
                  <!-- Counter Icon -->
                  <div class="counter-icon">
                    <i class="icofont-heart-alt"></i>
                  </div>
                  <!-- Counter Text -->
                  <div class="counter-text">
<!--                     <span class="counter d-inline-block mt-3 mb-2">1895</span>  -->
                    <h5>SMA</h5>
                  </div>
                </div>
              </li>
              <li>
                <!-- Single Counter -->
                <div class="single-counter px-4 py-3 text-center">
                  <!-- Counter Icon -->
                  <div class="counter-icon">
                    <i class="icofont-beard"></i>
                  </div>
                  <!-- Counter Text -->
                  <div class="counter-text">
<!--                     <span class="counter d-inline-block mt-3 mb-2">1580</span>  -->
                    <h5>Desarrolladores</h5>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
        <!-- Featured Items -->
        <div class="featured-items">
          <ul>
            <li>
              <!-- Single Features Item -->
              <div class="single-features media p-3">
                <!-- Features Title -->
                <div class="features-title mr-3">
                  <img src="assets/img/simple.svg" alt="">
                </div>
                <!-- Features Text -->
                <div class="features-text media-body">
                  <h3>Intuitivo &amp; Simple</h3>
                  <p>SMA es un ERP fácil de utilizar sin complicaciones .</p>
                </div>
              </div>
            </li>
            <li>
              <!-- Single Features Item -->
              <div class="single-features media p-3">
                <!-- Features Title -->
                <div class="features-title mr-3">
                  <img src="assets/img/icon_2.svg" alt="">
                </div>
                <!-- Features Text -->
                <div class="features-text media-body">
                  <h3>Creativo</h3>
                  <p>Con su diseño creativo y estratégico que se adapta a tu empresa.</p>
                </div>
              </div>
            </li>
            <li>
              <!-- Single Features Item -->
              <div class="single-features media p-3">
                <!-- Features Title -->
                <div class="features-title mr-3">
                  <img src="assets/img/interface.svg" alt="">
                </div>
                <!-- Features Text -->
                <div class="features-text media-body">
                  <h3>Interfaz Amigable</h3>
                  <p>Con nuestra interfaz la cual es amigable al usuario.</p>
                </div>
              </div>
            </li>
            <li>
              <!-- Single Features Item -->
              <div class="single-features media p-3">
                <!-- Features Title -->
                <div class="features-title mr-3">
                  <img src="assets/img/settings.svg" alt="">
                </div>
                <!-- Features Text -->
                <div class="features-text media-body">
                  <h3>Caracteristicas</h3>
                  <p>Con sus diferetes módulos que cubren todos los departamentos de tu Empresa.</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
