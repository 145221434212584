<div class="faq-page">
    <app-scrollup></app-scrollup>
    <div class="all-area">
      <app-header-two></app-header-two>
      <app-whabutton></app-whabutton>
      <app-breadcrumb-terms></app-breadcrumb-terms>
        <div class="bg-gray">
          <section class="section skills-area bg-gray ptb_100">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-12 col-lg-6 mb-5 mb-lg-0">
                  <div class="skills-content">
                    <h2>Terminos & Condiciones</h2>
                    <p class="d-none d-sm-block my-3">Bienvenido a los términos de Sistama de Módulos Administrativos</p>
                    <p class="d-none d-sm-block my-3">Al comprar uno de nuestros servicios, aceptas que: (i) eres responsable de leer el listado completo de los servicios antes de comprometerte a comprarlo. (ii) celebras un contrato legalmente vinculante para comprar un servicio cuando te comprometed a comprar un servicio y completar el proceso de check-out.</p>
                    <p class="d-none d-sm-block my-3">Los precios que cobramos por usar nuestros servicios para nuestros productos se enumeran en  la página web. Nos reservamos el derecho de cambiar nuestros precios para los productos que se muestran en cualquier momento y de corregir los errores de precios que pueden ocurrir inadvertidamente. Información adicional sobre precios e impuestos sobre las ventas está disponible en la página de pagos.</p>
                  </div>
                </div>
                <div class="col-12 col-md-8 col-lg-5 mx-lg-auto">
                  <!-- Image terms -->
                  <div class="about-thumb text-center d-none d-lg-block">
                    <img src="assets/img/terms.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <app-footer></app-footer>
    </div>
</div>
