<section class="section faq-area bg-gray ptb_100">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>Como puedo realizar mis pagos?</h5>
            </div>
          </div>
          <p class="mt-3">Los pago los puedes realizar por medio de tarjeta de Crédito o débito, por transferencias y dépositos.</p>
          <br>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>Que pasa si decido cancelar el servicio mensual?</h5>
            </div>
          </div>
          <p class="mt-3">Se suspende el servicio y la información permanecera en nuestros servidores por un periodo máximo de 6 meses luego se eliminará de manera automática.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>Puedo adicionar usuarios en mi paquete mensual?</h5>
            </div>
          </div>
          <p class="mt-3">Si, lo puede realizar por medio de nuestros asesores los cuales lo atenderan amablemente.</p>
          <br>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>Como adicionar el soporte técnico a mi paquete actual?</h5>
            </div>
          </div>
          <p class="mt-3">En el paquete Básico y Standard viene incluido el soporte técnico, en el paquete Vitalicia contamos con diferentes paquetes, puede abocarse a uno de nuestros asesores.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>Puedo solicitar la información que tenga en sus servidores al cancelar el servicio mensual?</h5>
            </div>
          </div>
          <p class="mt-3">Así es, lo puede realizar por medio de nuestros asesores los cuales le proveerán dicha información solicitada.</p>
          <br>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>SMA maneja todos los requerimientos por el SAR?</h5>
            </div>
          </div>
          <p class="mt-3">Nuestro ERP cuenta con todos los requerimientos solicitados por el SAR, estamos actualizando nuestro ERP en todo momento.</p>
          <p></p>
          <br>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <!-- Single Faq -->
        <div class="single-faq bg-white px-4 py-3">
          <div class="media">
            <img class="rounded-circle avatar-sm" src="assets/img/LogoSMA.png" alt="">
            <div class="media-body align-self-center ml-3">
              <h5>SMA esta conectado con los servidores del SAR?</h5>
            </div>
          </div>
          <p class="mt-3">Nuestro ERP tiene sus propios servidores, no son los que utiliza SAR.</p>
          <br>
        </div>
      </div>
    </div>
  </div>
</section>
