<section id="home" class="section welcome-area d-flex align-items-center h-100vh pt-0">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <img src="assets/img/LogoSMA_02.png" alt="" width="300" height="100">
      <!-- Welcome Intro Start -->
      <div class="col-12 col-md-10 col-lg-8">
        <div class="welcome-intro text-center mt-0">
          <h1 class="text-white">SMA ERP<span class="badge badge-pill badge-light fw-4">v4.0</span></h1>
          <h2 class="cd-headline clip fw-3 mt-2 mt-sm-3">
            <span class="text-white">Creado para</span>
            <span class="cd-words-wrapper ml-2">
              <b class="text-white is-visible">Farmacias/Droguerías</b>
              <b class="text-white">Servicios Múltiples</b>
              <b class="text-white">Comercializadoras</b>
              <b class="text-white">Agroindustria</b>
            </span>
          </h2>

          <div class="button-group">
            <a href="#pricing" class="btn btn-bordered-white"><span>Comprar Ahora</span></a>
            <a href="#" class="btn btn-bordered-white">Ver Demo</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shapes-container">
    <div class="wave-animation">
      <!-- Wave Animation -->

      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>

        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(244,151,44,0.7)" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(41,95,147,0.7)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(108,184,69,0.7)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
  </div>
</section>
