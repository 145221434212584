<section class="section skills-area bg-gray ptb_100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6 mb-5 mb-lg-0">
        <div class="skills-content">
          <h2>Paquete Standard</h2>
          <h3>Este paquete cuenta con:</h3>
          <h5 class="mt-3">Módulo Administrativo</h5>
          <p class="my-2">Facturación.</p>
          <p class="my-2">Compras.</p>
          <p class="my-2">Control de Inventario.</p>
          <p class="my-2">Control de Gastos.</p>
          <p class="my-2">Cuentas por Cobrar.</p>
          <p class="my-2">Cuentas por Pagar.</p>
          <p class="my-2">Banco.</p>
          <p class="my-2">Informes.</p>
          <h5 class="my-2">1 Usuario</h5>
          <p class="my-2">Puede adicionar usuarios.</p>
          <h5 class="my-2">Módulo Contabilidad</h5>
          <p class="my-2">Catálogo de cuentas contables</p>
          <p class="my-2">Registro de asientos contables manuales</p>
          <p class="my-2">Registro de asientos contables automatizados.</p>
          <p class="my-2">Balance de comprobación</p>
          <p class="my-2">Estado de Pérdidas y Ganancias</p>
          <p class="my-2">Balance General</p>
          <p class="my-2">Auxiliar de documentos de compras y ventas</p>
          <p class="my-2">Movimiento de las Cuentas (Mayor)</p>
          <p class="my-2">Resumen de Movimiento de Cuentas</p>
          <p class="my-2">Cierres Contables</p>
          <p class="my-2">Centros de Costos</p>
          <h5 class="my-2">24/7 Soporte Técnico</h5>
          <h5 class="my-2">Manual en Digital</h5>
          <h5 class="my-2">Todos los Documentos fiscales</h5>
          <h5 class="my-2">Opcional implementación y Capacitación</h5>
          <p class="my-2">Por nuestro personal capacitado por un pago único de 248$.</p>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-5 mx-lg-auto">
        <!-- Image skills -->
        <div class="about-thumb text-center d-none d-lg-block">
          <img src="assets/img/Logoskill.png" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
