<section id="pricing" class="section price-plan-area ptb_100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-6">
        <!-- Section Heading -->
        <div class="section-heading text-center">
          <h2 class="text-capitalize">Paquetes</h2>
          <p class="d-none d-sm-block mt-4">Contamos con diferentes paquetes que se adaptan a tu presupuesto.</p>
          <p class="d-none d-sm-block mt-4">Nos especializamos en pequeña, mediana y grande Empresa.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="price-plan-wrapper">
        <div class="single-price-plan">
          <div class="price-header d-flex align-items-center justify-content-between my-3 px-3">
            <h4 class="plan-title text-uppercase">
              Vitalicia
            </h4>
            <div class="plan-cost">
              <span class="plan-price">Básico</span>
              <span class="plan-type text-uppercase"></span>
            </div>
          </div>
          <ul class="plan-features pb-3">
            <li><i class="icofont-check"></i>Facturación</li>
            <li><i class="icofont-check"></i>Compras</li>
            <li><i class="icofont-check"></i>Control de Inventario</li>
            <li><i class="icofont-check"></i>Control de Gastos</li>
            <li><i class="icofont-check"></i>Cuentas por Cobrar</li>
            <li><i class="icofont-check"></i>Cuentas por Pagar</li>
            <li><i class="icofont-check"></i>Banco</li>
            <li><i class="icofont-check"></i>Informes</li>
            <br>
            <li><i class="icofont-check"></i>1 Empresa</li>
            <li><i class="icofont-check"></i>1 Sucursal</li>
            <br>
            <li><i class="icofont-check"></i>Multi-Usuarios</li>
            <li><i class="icofont-check"></i>Multi-Maquinas</li>
          </ul>
          <div class="plan-select">
            <a class="btn btn-bordered text-uppercase" href="https://wa.me/50431737536">Mas Información</a>
          </div>
        </div>
        <div class="single-price-plan">
          <div class="price-header d-flex align-items-center justify-content-between my-3 px-3">
            <h4 class="plan-title text-uppercase">
              Vitalicia
            </h4>
            <div class="plan-cost">
              <span class="plan-price">ERP</span>
              <span class="plan-type text-uppercase">Completo</span>
            </div>
          </div>
          <ul class="plan-features pb-3">
            <li><i class="icofont-arrow-right"></i><strong>Administrativo Contable</strong></li>
            <li><i class="icofont-check"></i>Módulo de Contabilidad</li>
            <li><i class="icofont-check"></i>Módulo de Bancos</li>
            <li><i class="icofont-check"></i>Módulo de Compras y SRM</li>
            <li><i class="icofont-check"></i>Módulo de Ventas y CRM</li>
            <li><i class="icofont-check"></i>Módulo de Inventario</li>
            <li><i class="icofont-check"></i>Módulo de CxC y Cxp</li>
            <li><i class="icofont-check"></i>Módulo de Importaciones</li>
            <li><i class="icofont-check"></i>Módulo de Planilla y RRHH</li>
            <li><i class="icofont-check"></i>Módulo de Activos Fijos</li>
            <li><i class="icofont-check"></i>Módulo de Analítica & BI</li>
            <!-- <br> -->
            <li><i class="icofont-arrow-right"></i><strong>Módulos Especializados</strong></li>
            <li><i class="icofont-check"></i>Módulo MRP</li>
            <li><i class="icofont-check"></i>Módulo de Gestión de Proyectos</li>
            <li><i class="icofont-check"></i>Módulo de Gestión de Talleres</li>
            <li><i class="icofont-check"></i>Módulo de Gestión de Flota Vehicular</li>
            <!-- <br> -->
            <li><i class="icofont-check"></i>1 Empresa</li>
            <li><i class="icofont-check"></i>Multi-Sucursal</li>
            <li><i class="icofont-check"></i>Multi-Usuarios</li>
            <li><i class="icofont-check"></i>Multi-Maquinas</li>
          </ul>
          <div class="plan-select">
            <a class="btn btn-bordered text-uppercase" href="https://wa.me/50431737536">Mas Información</a>
          </div>
        </div>
        <div class="single-price-plan">
          <div class="price-header d-flex align-items-center justify-content-between my-3 px-3">
            <h4 class="plan-title text-uppercase">
              Smart
            </h4>
            <div class="plan-cost">
              <span class="plan-price">Ecommerce</span>
              <span class="plan-type text-uppercase"></span>
            </div>
          </div>
          <ul class="plan-features pb-3">
            <li><i class="icofont-arrow-right"></i><strong>Integrado a SMA ERP</strong></li>
            <br>
            <li><i class="icofont-check"></i>Tienda en Línea</li>
            <li><i class="icofont-check"></i>Categorías de Productos</li>
            <li><i class="icofont-check"></i>Búsqueda por palabras Claves</li>
            <li><i class="icofont-check"></i>Carrito de Compras</li>
            <li><i class="icofont-check"></i>Inventario en Línea con SMA</li>
          </ul>
          <div class="plan-select">
            <a class="btn btn-bordered text-uppercase" href="https://wa.me/50431737536">Mas Información</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
