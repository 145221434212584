<section class="section about-app-area ptb_100">
  <div class="shapes-container">
    <div class="shape-1"></div>
  </div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6">
        <!-- About Text -->
        <div class="about-text">
          <!-- Headings -->
          <div class="headings d-flex align-items-center mb-4">
            <span class="text-uppercase d-none d-sm-block">Sobre</span>
            <h2 class="text-capitalize">SMA maneja tu Empresa con Fácilidad. </h2>
          </div>
          <p class="my-3">Gestiona tu Empresa o Negocio Desde una sola Herramienta.</p>
          <p class="my-3">SMA es un Sistema ERP que puedes utilizar como aliado estratégico, para controlar de inicio a fin todos los pocesos operativos de tu Empresa o Negocio es Adaptable a cualquier rubro o actividad económica.</p>
          <!-- Store Buttons -->
          <div class="button-group store-buttons">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <!-- About Thumb -->
        <div class="about-thumb text-center d-none d-lg-block">
          <img src="assets/img/terms.png" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
