<div class="about-page">
  <app-scrollup></app-scrollup>
  <div class="all-area">
    <app-header-two></app-header-two>
    <app-whabutton></app-whabutton>
    <app-breadcrumb-about></app-breadcrumb-about>
    <app-skills></app-skills>
    <app-about-us></app-about-us>
    <app-promo-three></app-promo-three>
<!--    <app-team></app-team>  -->
    <app-footer></app-footer>
  </div>
</div>
