<section id="benifits" class="section benifits-area ptb_100">
  <div class="container">
    <div class="row">
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/features_icon_2.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Facil de Usar</h3>
            <p>Con su diseño el cúal es intuitivo no necesita ser un experto en tecnología para porder utilizar nuestras herramientas, te fácilitamos todo el trabajo para que solo te enfoques en tu empresa.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/features_icon_3.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">24/7 Soporte</h3>
            <p>Te brindamos soporte ya incluido en los paquetes Básico y Standard en el cual atendemos tus dudas algún cambio que desees para mejorar tu fluidez de trabajo.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/features_icon_4.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Liviano</h3>
            <p>Esta Diseñado para que trabaje en todos los dispositivos como ser Desktop, laptop, tablet y celurares.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/features_icon_5.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Diseño Moderno</h3>
            <p>Lo hemos diseñado de una manera moderna con todas las herraminetas necesarias para que lleves el control de tu Empresa.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/features_icon_6.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Documentado</h3>
            <p>Tenemos un manual muy explícito en el cual te muestra y te va guiando paso a paso, por si deseas tener soporte extra y al alcance de tu mano.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
