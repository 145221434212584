<div class="about-page">
  <app-scrollup></app-scrollup>
  <div class="all-area">
    <app-header-two></app-header-two>
    <app-whabutton></app-whabutton>
    <app-breadcrumb-basic></app-breadcrumb-basic>
    <app-standard></app-standard>
    <app-footer></app-footer>
  </div>
</div>
