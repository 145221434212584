import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemeNineComponent } from './themes/theme-nine/theme-nine.component';
import { MaintenanceComponent } from './components/inner-pages/maintenance/maintenance.component';
import { TermsComponent } from './components/inner-pages/terms/terms.component';
import { FaqPageComponent } from './components/inner-pages/faq-page/faq-page.component';
import { AboutPageComponent } from './components/inner-pages/about-page/about-page.component';
import { ContactPageComponent } from './components/inner-pages/contact-page/contact-page.component';
import { BasicPageComponent } from './components/inner-pages/basic-page/basic-page.component';
import { StandardPageComponent } from './components/inner-pages/standard-page/standard-page.component';
import { PrivacyPageComponent } from './components/inner-pages/privacy-page/privacy-page.component';


const routes: Routes = [
  {path: '', component: ThemeNineComponent},
  {path: 'maintenance', component: MaintenanceComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'faq-page', component: FaqPageComponent},
  {path: 'about-page', component: AboutPageComponent},
  {path: 'contact-page', component: ContactPageComponent},
  {path: 'basic-page', component: BasicPageComponent},
  {path: 'standard-page', component: StandardPageComponent},
  {path: 'privacy-page', component: PrivacyPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
