<section class="section breadcrumb-area d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Breamcrumb Content -->
        <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
          <h3>Paquetes</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="text-uppercase" href="/">Inicio</a></li>
            <li class="breadcrumb-item active">Paquetes</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>