<div class="italy">
  <!-- Modal -->
  <!-- <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Seguimos Trabajando</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Seguimos actualizando nuestro sitio.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div> -->
  <app-scrollup></app-scrollup>
  <app-whabutton></app-whabutton>
  <div class="all-area">
    <app-header-two></app-header-two>
    <app-hero-nine></app-hero-nine>
    <app-video-page></app-video-page>
    <app-promo-one></app-promo-one>
    <app-about></app-about>
<!--    <app-work></app-work>  -->
    <app-features></app-features>
    <app-screenshot></app-screenshot>
    <app-pricing></app-pricing>
<!--    <app-reviews></app-reviews>
    <app-team></app-team>
    <app-newsletter></app-newsletter>
    <app-download></app-download>
    <app-blog></app-blog>  -->
    <app-contact></app-contact>
    <app-footer></app-footer>
  </div>
</div>
