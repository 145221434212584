<section id="screenshots" class="section screenshots-area ptb_100">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- App Screenshot Slider Area -->
        <div class="app-screenshots">
          <!-- Single Screenshot Item -->
          <div class="single-screenshot">
            <img src="assets/img/dashboard.jpeg" alt="">
            <!-- Screenshots Overlay -->
            <div class="screenshots-overlay">
              <a href="assets/img/dashboard.jpeg" data-fancybox="images"><i class="icofont-image"></i></a>
            </div>
          </div>
          <!-- Single Screenshot Item -->
          <div class="single-screenshot">
            <img src="assets/img/cierre.jpeg" alt="">
            <!-- Screenshots Overlay -->
            <div class="screenshots-overlay">
              <a href="assets/img/cierre.jpeg" data-fancybox="images"><i class="icofont-image"></i></a>
            </div>
          </div>
          <!-- Single Screenshot Item -->
          <div class="single-screenshot">
            <img src="assets/img/compras.jpeg" alt="">
            <!-- Screenshots Overlay -->
            <div class="screenshots-overlay">
              <a href="assets/img/compras.jpeg" data-fancybox="images"><i class="icofont-image"></i></a>
            </div>
          </div>
          <!-- Single Screenshot Item -->
          <div class="single-screenshot">
            <img src="assets/img/ss-1.jpg" alt="">
            <!-- Screenshots Overlay -->
            <div class="screenshots-overlay">
              <a href="assets/img/ss-1.jpg" data-fancybox="images"><i class="icofont-image"></i></a>
            </div>
          </div>
          <!-- Single Screenshot Item -->
          <div class="single-screenshot">
            <img src="assets/img/ss-2.jpg" alt="">
            <!-- Screenshots Overlay -->
            <div class="screenshots-overlay">
              <a href="assets/img/ss-2.jpg" data-fancybox="images"><i class="icofont-image"></i></a>
            </div>
          </div>
          <!-- Single Screenshot Item -->
          <div class="single-screenshot">
            <img src="assets/img/ss-3.jpg" alt="">
            <!-- Screenshots Overlay -->
            <div class="screenshots-overlay">
              <a href="assets/img/ss-3.jpg" data-fancybox="images"><i class="icofont-image"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
