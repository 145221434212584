<section id="contact" class="contact-area bg-gray ptb_100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6 mb-5 mb-lg-0">
        <div class="skills-content">
          <h2>Contáctanos</h2>
          <p class="d-none d-sm-block my-3">Nos puedes contactar por nuestras Redes Sociales o nuestro correoo través de nuestros números telefónicos.</p>
          <p class="d-none d-sm-block my-3"></p>
        </div>
        <div class="counter-list">
          <ul>
            <li>
              <!-- Single Counter -->
              <div class="single-counter px-4 py-3 text-center">
                <!-- Counter Icon -->
                <div class="counter-icon">
                  <i class="icofont-facebook"></i>
                </div>
                <!-- Counter Text -->
                <div class="counter-text">
                  <a class="facebook" href="https://www.facebook.com/SISSDERL">
                    <h5>Facebook</h5>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <!-- Single Counter -->
              <div class="single-counter px-4 py-3 text-center">
                <!-- Counter Icon -->
                <div class="counter-icon">
                  <i class="icofont-instagram"></i>
                </div>
                <!-- Counter Text -->
                <div class="counter-text">
                  <a class="instagram" href="https://www.instagram.com/sissderl/">
                    <h5>Instagram</h5>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <!-- Single Counter -->
              <div class="single-counter px-4 py-3 text-center">
                <!-- Counter Icon -->
                <div class="counter-icon">
                  <i class="icofont-brand-whatsapp"></i>
                </div>
                <!-- Counter Text -->
                <div class="counter-text">
                  <a class="whatsapp" href="https://wa.me/50431737536">
                    <h5>WhatsApp</h5>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <!-- Single Counter -->
              <div class="single-counter px-4 py-3 text-center">
                <!-- Counter Icon -->
                <div class="counter-icon">
                  <i class="icofont-envelope"></i>
                </div>
                <!-- Counter Text -->
                <div class="counter-text">
                  <a class="email" href="mailto:info@smasis.com?Subject=información">
                    <h5>Correo</h5>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-5 mx-lg-auto">
        <!-- Image skills -->
        <div class="about-thumb text-center d-none d-lg-block">
          <img src="assets/img/Logoskill.png" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
