<section id="team" class="section team-area ptb_100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-6">
        <!-- Section Heading -->
        <div class="section-heading text-center">
          <h2 class="text-capitalize">Conoce a Nuestro equipo de Expertos</h2>
          <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
          <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Team Slider -->
      <div class="team-slider owl-carousel">
        <!-- Single Team -->
        <div class="single-team text-center px-3 py-2 m-2">
          <!-- Team Photo -->
          <div class="team-photo mx-auto">
            <img src="assets/img/avatar_1.png" alt="">
          </div>
          <!-- Team Content -->
          <div class="team-content mt-2 mb-1">
            <h3 class="team-name mt-3 mb-2">Jonayed Hasan</h3>
            <h5 class="team-post mb-2">Co-Founder &amp; CEO</h5>
            <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
            <!-- Team Media -->
            <div class="team-media mt-3">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-google-plus-g"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- Single Team -->
        <div class="single-team text-center px-3 py-2 m-2">
          <!-- Team Photo -->
          <div class="team-photo mx-auto">
            <img src="assets/img/avatar_2.png" alt="">
          </div>
          <!-- Team Content -->
          <div class="team-content mt-2 mb-1">
            <h3 class="team-name mt-3 mb-2">Jassica William</h3>
            <h5 class="team-post mb-2">Web Developer</h5>
            <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
            <!-- Team Media -->
            <div class="team-media mt-3">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-google-plus-g"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- Single Team -->
        <div class="single-team text-center px-3 py-2 m-2">
          <!-- Team Photo -->
          <div class="team-photo mx-auto">
            <img src="assets/img/avatar_3.png" alt="">
          </div>
          <!-- Team Content -->
          <div class="team-content mt-2 mb-1">
            <h3 class="team-name mt-3 mb-2">John Deo</h3>
            <h5 class="team-post mb-2">ISO Developer</h5>
            <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
            <!-- Team Media -->
            <div class="team-media mt-3">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-google-plus-g"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- Single Team -->
        <div class="single-team text-center px-3 py-2 m-2">
          <!-- Team Photo -->
          <div class="team-photo mx-auto">
            <img src="assets/img/avatar_4.png" alt="">
          </div>
          <!-- Team Content -->
          <div class="team-content mt-2 mb-1">
            <h3 class="team-name mt-3 mb-2">Johnson Smith</h3>
            <h5 class="team-post mb-2">Android Developer</h5>
            <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
            <!-- Team Media -->
            <div class="team-media mt-3">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-google-plus-g"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- Single Team -->
        <div class="single-team text-center px-3 py-2 m-2">
          <!-- Team Photo -->
          <div class="team-photo mx-auto">
            <img src="assets/img/avatar_5.png" alt="">
          </div>
          <!-- Team Content -->
          <div class="team-content mt-2 mb-1">
            <h3 class="team-name mt-3 mb-2">Johnson Smith</h3>
            <h5 class="team-post mb-2">Android Developer</h5>
            <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
            <!-- Team Media -->
            <div class="team-media mt-3">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-google-plus-g"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
