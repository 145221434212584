<section class="section skills-area bg-gray ptb_100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6 mb-5 mb-lg-0">
        <div class="skills-content">
          <h2>Tu empresa es Importante para Nosotros</h2>
          <p class="my-3">Nosotros en SIS nos preocupamos por brindarte el mejor Servicio, Asesoría ténica, Operativa y Soporte.</p>
          <p class="my-3">Para que tengas el mejor control sobre tu empresa nuestro sistema es adaptable a todos los rúbros, Puedes llevar tu Contabilidad, Control de Inventario, control de Importaciones, Planilla y mucho más.</p>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-5 mx-lg-auto">
        <!-- Image skills -->
        <div class="about-thumb text-center d-none d-lg-block">
          <img src="assets/img/Logoskill.png" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
