<section class="section about-us ptb_100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-5 mx-auto mb-4 mb-lg-0">
        <img src="assets/img/code-2.png" alt="">
      </div>
      <div class="col-12 col-lg-6">
        <h2>Creamos un ERP potente para ti.</h2>
        <p class="my-3">Nos esforzamos por traerte lo mejor con SMA para que puedas llevar un excelente manejo de tu empresa, ya que en SIS somos un equipo responsable y profesional</p>
        <p class="my-3">Te brindamos el mejor soporte técnico, cualquier duda o sugerencia estamos aquí para atenderte</p>
      </div>
    </div>
  </div>
</section>
