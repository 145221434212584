<section id="benifits" class="section benifits-area ptb_100">
  <div class="container">
    <div class="row">
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/promo_icon_1.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Capacitación</h3>
            <p>Te brindamos una capacitación para tu personal.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/promo_icon_2.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Fácil de Utilizar</h3>
            <p>Es un programa que es fácil de usar y muy intuitivo.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/promo_icon_3.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">24/7 Soporte</h3>
            <p>Te brindamos soporte técnico.</p>
          </div>
        </div>
      </div>
      <!-- Benifits Item -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="benifits-item text-center p-3">
          <div class="feature-icon">
            <img src="assets/img/promo_icon_4.png" alt="">
          </div>
          <!-- Benifits Text -->
          <div class="benifits-text">
            <h3 class="mb-2">Bien Documentado</h3>
            <p>Contamos con un manual detallado de todos los Módulos.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
